import React, { useState, useContext, memo } from "react";
import { Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DotsLoading } from "../loader/Loader";
import { chart_filter_context } from "./CompanyStatsFunc";
import DataTable from "../tabledata/TableData";
import { ThreeCircles } from "react-loader-spinner";
import { handle_add_query_to_dashboard } from "./companyglobalfunc";
import DashboardTableFilters from "../modalContainer/DashboardTableFilters";
import { dashbaord_search_context } from "./UniversalTableDashboard";
import { options_post_category } from "../../../Assets/globalfunctions";
function DashboardTelegramTable() {
  const {
    dashboard_chart_list_array,
    set_dashboard_chart_list_array,
    get_individual_chart_alert_stats,
  } = useContext(chart_filter_context);

  const {
    telegram_data,
    getSearchedData,
    table_type,
    set_table_type,
    add_query_to_dashboard_loading,
    set_add_query_to_dashboard_loading,
    filter_enabled,
  } = useContext(dashbaord_search_context);
  const [open_dash_table_filters, set_open_dash_table_filters] =
    useState(false);
  const [hybrid_channels, set_hybrid_channels] = useState({
    marked_channels: {
      company_preference: [],
      user_preference: [],
    },
    unmarked_channels: [],
  });
  const [paginationValue, setPaginationValue] = useState(1);
  const [postCategory, setPostCategory] = useState(options_post_category);
  const [keyword, setKeyword] = useState("contains");
  const [post_search_sub, set_search_sub] = useState("Channel/Group");
  const [template_telegram_filters, set_template_telegram_filters] = useState({
    post_search_sub,
    keyword,
  });
  const handle_dashboard_access_version_2 = (new_chart_list) => {
    const list_of_previous_chart_id = [...dashboard_chart_list_array].map(
      (item) => item.id,
    );
    const dashboard_chart_list = [...new_chart_list];
    const filter_new_id = dashboard_chart_list.filter(
      (item) =>
        list_of_previous_chart_id.includes(item.id) === false &&
        item.is_enabled,
    );
    if (Array.isArray(filter_new_id) && filter_new_id.length > 0) {
      const clone_obj = { ...filter_new_id[0] };
      const new_obj = {
        dashboard_filter: clone_obj["dashboard_filter"],
        dashboard_type: clone_obj["dashboard_type"],
        id: clone_obj["id"],
        keyword: clone_obj["value"],
      };
      get_individual_chart_alert_stats(new_obj);
      setTimeout(() => set_dashboard_chart_list_array(dashboard_chart_list));
    }
  };
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "97%" }}
      >
        <h4 className="dashboard_search_table_heading">
          Telegram post/title search
        </h4>
        <Stack direction={"row"} columnGap={2} alignItems="baseline">
          <button
            className="dashbaord_search_chart_filter_btn"
            onClick={() => {
              set_open_dash_table_filters(true);
              set_table_type("keyword");
            }}
          >
            <FilterListIcon
              sx={{ color: "rgb(144, 202, 249)" }}
              fontSize="large"
            />
          </button>
          {add_query_to_dashboard_loading.keyword_loading ? (
            <DotsLoading />
          ) : (
            <button
              className="dashbaord_search_chart_add_btn"
              onClick={() =>
                handle_add_query_to_dashboard(
                  getSearchedData,
                  "keyword",
                  set_add_query_to_dashboard_loading,
                  handle_dashboard_access_version_2,
                )
              }
            >
              {" "}
              Add to dashboard
            </button>
          )}
        </Stack>
      </Stack>
      <div style={{ position: "relative" }}>
        <div className="dashbaord_table_telegram_search scroll-custom">
          {telegram_data.loading === true ? (
            <div className="spinner">
              <ThreeCircles
                color="#28cab3"
                height={60}
                width={60}
                ariaLabel="three-circles-rotating"
              />
            </div>
          ) : telegram_data.error ? (
            <div className="main__table no_data">{telegram_data.error}</div>
          ) : telegram_data.data.length === 0 ? (
            <div className="main__table no_data">No data to display</div>
          ) : (
            <DataTable
              teleSearch={"post"}
              Data={telegram_data.data}
              getSearchedData={getSearchedData}
              postSearch_props={"None"}
              postsearch_sub={
                filter_enabled["keyword"]
                  ? template_telegram_filters["postsearch_sub"]
                  : "Channel/Group"
              }
              telegramOption_post_props={"None"}
              telegramOption_user={"username"}
              telegramOption_channel={"conv_name"}
              total_db_data={telegram_data.total_data || 100}
              telegram_limit={telegram_data.rate_limit || 100}
              set_telegram_limit={() => {}}
              file_id={telegram_data.file_id || ""}
              user_group={"group_channel_name"}
              getData={() => {}}
              loadmore_loading={false}
              keyword={
                filter_enabled["keyword"]
                  ? template_telegram_filters["keyword"]
                  : "contains"
              }
              proximity={0}
              search_after_id={null}
              post_no={100}
              nested_channel_regex={false}
              hybrid_channels={hybrid_channels}
              set_hybrid_channels={set_hybrid_channels}
              paginationValue={paginationValue}
              setPaginationValue={setPaginationValue}
              table_mode="dashboard_search_table"
            />
          )}
        </div>
        {table_type === "keyword" && open_dash_table_filters ? (
          <DashboardTableFilters
            set_open_modal={set_open_dash_table_filters}
            graph_type={"Default"}
            postCategory={postCategory}
            setPostCategory={setPostCategory}
            keyword={keyword}
            setKeyword={setKeyword}
            post_search_sub={post_search_sub}
            set_search_sub={set_search_sub}
            set_template_telegram_filters={set_template_telegram_filters}
          />
        ) : null}
      </div>
    </div>
  );
}

export default memo(DashboardTelegramTable);
