import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { handle_open_channel_scrape_modal } from "../../../actions/filterAction";
import {
  handle_session_get,
  handle_clear_localStorage,
} from "../../../utils/SessionStorage/handle_session";
import "../../../css/Navbar/navbar.css";
/*
NOTE :
->This component handles the navigation for the searches
Telegram, Breach, Forum, User,Tag, Stearler logs and Dashboard 
-> Also It includes the user name, User profile and logout
*/
function Navbar({
  stateChanger,
  selectedTab,
  setSelectedTab,
  session,
  show_sidenav,
  set_show_sidenav,
}) {
  useEffect(() => {
    session();
  }, []);
  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
    stateChanger(newValue);
  };
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user_name_session = handle_session_get("admin_status");
  const customer_name = handle_session_get("User");
  const dashboard_status = Boolean(handle_session_get("dashboard_status"));
  const account_type = handle_session_get("Acc_typ");
  return (
    <div className="navbar__main">
      <Stack alignItems="center" direction="row" sx={{ pl: 2 }}>
        {user_name_session === "super_admin" ||
        user_name_session === "admin" ? (
          <MenuIcon
            sx={{ color: "white", cursor: "pointer", mr: 2 }}
            onClick={() => set_show_sidenav(!show_sidenav)}
          />
        ) : null}
        <Link to="/dashboard">
          <img
            width="0"
            height="0"
            // eslint-disable-next-line no-undef
            src={`${process.env.PUBLIC_URL}/static/images/logo.png`}
            alt="logo"
          />
        </Link>
        <Link to="/dashboard">
          {" "}
          <h3 className="navbar_main_title" />
        </Link>
      </Stack>

      <Box
        sx={{
          width: "contain",
          typography: "body1",
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeSelectedTab}
              aria-label="lab API tabs example"
              indicatorColor="secondary"
            >
              <Tab label="Telegram" value="telegram" className="tab__label" />
              <Tab label="Breach" value="breach" className="tab__label" />
              <Tab label="Forum" value="forum" className="tab__label" />
              {/* <Tab label="Dark web search" value="breach_2" className='tab__label' /> */}
              <Tab
                label="User Search"
                value="user_search"
                className="tab__label"
              />
              <Tab
                label="Tag Search"
                value="card_search"
                className="tab__label"
              />
              <Tab
                label="Stealer Logs"
                value="stealer_logs_search"
                className="tab__label"
              />
            </TabList>
          </Box>
        </TabContext>
        {handle_session_get("company_status") !== "None" ||
        dashboard_status === true ? (
          <Link to="/company-statspage">
            <p className="navbar_telegram_search_link">Dashboard</p>
          </Link>
        ) : null}
      </Box>
      <Stack direction="row" alignItems="baseline">
        <p onClick={handleClick} className="user_name_navbar">
          {customer_name}
        </p>
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <AccountCircleIcon sx={{ color: "white", fontSize: "30px" }} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Link to="/userprof" style={{ width: "0px" }}>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
            </Link>
            <MenuItem
              onClick={() => {
                if (account_type !== "PAID_CUSTOMER") return;
                dispatch(handle_open_channel_scrape_modal(true));
                handleClose();
              }}
            >
              Add to scrapping{" "}
              <span className="user_add_to_scrape_access_info">premium</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                sessionStorage.clear();
                handle_clear_localStorage();
                window.location.href = "/";
                sessionStorage.clear();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Stack>
    </div>
  );
}
Navbar.propTypes = {
  stateChanger: PropTypes.func,
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func,
  session: PropTypes.func,
  show_sidenav: PropTypes.bool,
  set_show_sidenav: PropTypes.func,
};

export default Navbar;
