import React, { useState, useEffect, memo, useCallback } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Formik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { Stack, Box } from "@mui/material";
import Categories from "../sections/Checkbox/Categories";
import { RollerAnimation } from "../loader/Loader";
import axios from "axios";
import Chip from "@mui/material/Chip";
import {
  card_type_options,
  crypto_options,
  email_options,
  exp_year_options,
  exp_month_options,
} from "../../../utils/Globals/global_variables";
// import { options_post_category } from "../../../Assets/globalfunctions";
import { stealer_logs_categories } from "../../../utils/Globals/global_variables";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_check_category_length } from "../../../Assets/globalfunctions";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_custom_debounce } from "../../../utils/Globals/global_functions";
import {
  handle_error_message,
  handle_success_message,
} from "../../../utils/ResponseMessage/response_message";

function UserDashboardInsertionFilters({
  set_open_modal,
  stealer_multiple_obj,
  set_stealer_multiple_obj,
  category_stealer,
  set_category_stealer,
  stealer_search_type,
  set_stealer_search_type,
  post_search_sub,
  set_search_sub,
  postCategory,
  setPostCategory,
  keyword,
  setKeyword,
  card_type,
  set_card_type,
  crypto_provider,
  set_crypto_provider,
  email_provider,
  set_email_provider,
  exp_year,
  set_exp_year,
  exp_month,
  set_exp_month,
  notification_filters,
  set_notification_filters,
  spam_filter,
  set_spam_filter,
  edit_chart_query,
  set_edit_chart_query,
  field_filter,
  set_field_filter,
  option_dropdown,
  notification_filters_tag,
  set_notification_filters_tag,
  stealer_filter_type,
  set_stealer_filter_type,
  channels_list,
  set_channels_list,
  handle_dashboard_insertion,
  channel_filter_type,
  set_channel_filter_type,
  chart_pref,
  set_chart_pref,
}) {
  const token = handle_session_get("Token");
  const [search_val, set_search_val] = useState("");
  const [search_error, set_search_error] = useState("");
  const [search_data, set_search_data] = useState(null);
  const [stat_loading, set_stat_loading] = useState(false);
  const handle_change_stealer_input = (event) => {
    const { id } = event.target;
    const { value } = event.target;
    set_stealer_multiple_obj((prev) => ({ ...prev, [id]: value }));
  };
  const isAllselectedExpYear =
    exp_year_options.length > 0 && exp_year.length === exp_year_options.length;

  const isAllselectedExpMonth =
    exp_month_options.length > 0 &&
    exp_month.length === exp_month_options.length;
  const isAllselectedCardType =
    card_type_options.length > 0 &&
    card_type.length === card_type_options.length;
  const isAllselectedCryptoProvider =
    crypto_options.length > 0 &&
    crypto_provider.length === crypto_options.length;
  const isAllselectedEmailProvider =
    email_options.length > 0 && email_provider.length === email_options.length;
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_card_type(
        card_type.length === card_type_options.length ? [] : card_type_options,
      );
    } else {
      const list_card_type = [...card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_card_type(list_card_type);
    }
  };
  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_crypto_provider(
        crypto_provider.length === crypto_options.length ? [] : crypto_options,
      );
    } else {
      const list_crypto_provider = [...crypto_provider];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_crypto_provider(list_crypto_provider);
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_email_provider(
        email_provider.length === email_options.length ? [] : email_options,
      );
    } else {
      const list_email_provider = [...email_provider];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_email_provider(list_email_provider);
    }
  };
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_year(
        exp_year.length === exp_year_options.length ? [] : exp_year_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_exp_year(list_exp_year);
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_month(
        exp_month.length === exp_month_options.length ? [] : exp_month_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_exp_month(list_exp_month);
    }
  };
  const val_change = (e) => {
    if (e.target.value.length >= 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_search_data(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_search_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    }
  };

  const handle_add_channels_list = (added_channel) => {
    set_channels_list((prev) => [...prev, added_channel]);
  };
  const handle_delete_channels = (name) => {
    const filter_channels = channels_list.filter((item) => item !== name);
    set_channels_list(filter_channels);
  };
  const channels_list_chips = channels_list.map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_channels(item)}
      color="success"
      key={index}
    />
  ));
  const debouncedFetchData = useCallback(
    handle_custom_debounce(val_change, 300),
    [],
  );
  return (
    <ClickAwayListener onClickAway={() => set_open_modal(false)}>
      <div className="dashboard_chart_filters_modal scroll-custom user_dashboard_insertion_filters_container">
        <Formik
          initialValues={{
            email: "",
            domain: "",
            password: "",
            country_code: "",
            ip_address: "",
            zip: "",
            location: "",
          }}
          validate={() => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              set_open_modal(false);
              if (
                option_dropdown === "channel" &&
                channel_filter_type === "individual"
              ) {
                handle_dashboard_insertion(edit_chart_query);
              }
              setSubmitting(false);
              return;
            });
          }}
        >
          {({ handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <h5 className="dashbord_chart_filter_title">Filter your data</h5>
              {option_dropdown === "channel" &&
              channel_filter_type === "individual" ? (
                <>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <input
                      type="text"
                      id="email"
                      name="text"
                      onChange={(event) => {
                        event.preventDefault();
                        handleChange(event);
                        set_search_val(event.target.value);
                        debouncedFetchData(event);
                      }}
                      onBlur={handleBlur}
                      className="chart_edit_value_input_box w-75"
                      value={search_val}
                      placeholder="enter channel/group"
                    />
                    {search_val.length >= 2 && option_dropdown === "channel" ? (
                      <div
                        className="autocom-box channel_list_container mt-3"
                        style={{ width: "90%", left: "4%", top: "60%" }}
                      >
                        {stat_loading ? (
                          <RollerAnimation />
                        ) : search_error ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "larger",
                              color: "white",
                              marginTop: "10%",
                            }}
                          >
                            {search_error}
                          </div>
                        ) : search_data !== null && search_data.length === 0 ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "larger",
                              color: "white",
                              marginTop: "10%",
                            }}
                          >
                            No Data to display
                          </div>
                        ) : (
                          <>
                            <h4
                              style={{
                                paddingLeft: "1rem",
                                marginLeft: "15px",
                                marginTop: "5px",
                                fontSize: "18px",
                                color: "whitesmoke",
                              }}
                            >
                              List Of Channels
                            </h4>
                            <ul>
                              {search_data !== null &&
                                search_data.map((val, id) => (
                                  <li key={id}>
                                    <div className="row">
                                      <div
                                        className="col-md-8"
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        <img
                                          style={{
                                            width: "36px",
                                            height: "36px",
                                            padding: "2px",
                                            border: "2px solid #f4f4f4",
                                          }}
                                          className="img-fluid rounded-circle"
                                          src={
                                            val.channel_id !== "None"
                                              ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                          }
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                          }}
                                          alt="User"
                                        />
                                        <span
                                          className="channels_list_map"
                                          style={{
                                            marginLeft: "10px",
                                            color: "#dcf3ff",
                                          }}
                                        >
                                          {val.channel_name}
                                        </span>
                                      </div>
                                      <div
                                        className="col-md-4"
                                        style={{
                                          textAlign: "right",
                                          marginTop: "5px",
                                        }}
                                      >
                                        <button
                                          style={{
                                            borderRadius: "40px",
                                            background: "#d03940",
                                            color: "white",
                                            lineHeight: "1",
                                          }}
                                          onClick={() => {
                                            handle_add_channels_list(
                                              val.channel_name,
                                            );
                                            set_search_val("");
                                          }}
                                          className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                  {channels_list_chips}
                  <Stack direction="row" justifyContent="flex-start">
                    <input
                      type="text"
                      id="email"
                      name="text"
                      onChange={(event) => {
                        event.preventDefault();
                        handleChange(event);
                        set_edit_chart_query(event.target.value);
                      }}
                      onBlur={handleBlur}
                      className="chart_edit_value_input_box w-75"
                      value={edit_chart_query}
                      placeholder="enter a keyword"
                    />
                  </Stack>
                </>
              ) : null}
              {option_dropdown !== "channel" && option_dropdown !== "tag" && (
                <FormControl className="dashboard_chart_radio_options">
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Filters
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={notification_filters}
                    onChange={(e) => set_notification_filters(e.target.value)}
                  >
                    <FormControlLabel
                      value="default"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Default"
                    />
                    <FormControlLabel
                      value="regex"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Regex"
                    />
                    {!["tag", "stealer log"].includes(option_dropdown) && (
                      <FormControlLabel
                        value="logical"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Logical"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              )}
              <FormControl className="dashboard_chart_radio_options">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Preference
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={chart_pref}
                  onChange={(e) => set_chart_pref(e.target.value)}
                >
                  <FormControlLabel
                    value="user"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="User"
                  />
                  <FormControlLabel
                    value="company"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Company"
                  />
                </RadioGroup>
              </FormControl>
              {option_dropdown === "stealer log" && (
                <div className="dashboard_chart_filters_modal_stealerslogs">
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      row
                      value={stealer_filter_type}
                      onChange={(e) => set_stealer_filter_type(e.target.value)}
                    >
                      <FormControlLabel
                        value="email"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Username/Email"
                      />
                      <FormControlLabel
                        value="domain"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Domain"
                      />
                      <FormControlLabel
                        value="password"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Password"
                      />
                      <FormControlLabel
                        value="country_code"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Country Code"
                      />
                      <FormControlLabel
                        value="ip_address"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="IP"
                      />
                      <FormControlLabel
                        value="zip"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Zip"
                      />
                      <FormControlLabel
                        value="location"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Location"
                      />
                      <FormControlLabel
                        value="device_id"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="MID/HWID/GUID"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div
                    className="modal_stealer_logs_multiple_search_input_container"
                    style={{ marginTop: "5px" }}
                  >
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="email">
                        Email / Username
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.email}
                        placeholder="enter email/username"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="domain">
                        Domain
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="domain"
                        name="domain"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.domain}
                        placeholder="enter domain"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="password"
                        name="password"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.password}
                        placeholder="enter password"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="country_code"
                      >
                        Country Code
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="country_code"
                        name="country_code"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.country_code}
                        placeholder="enter country code"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="ip_address"
                      >
                        IP Address
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="ip_address"
                        name="ip_address"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.ip_address}
                        placeholder="ente ip address"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="zip">
                        Zip
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="zip"
                        name="zip"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.zip}
                        placeholder="enter zip"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="location">
                        Location
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="location"
                        name="location"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.location}
                        placeholder="enter location"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="device_id">
                        MID/HWID/GUID
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="device_id"
                        name="device_id"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.device_id}
                        placeholder="enter MID/HWID/GUID"
                      />
                    </div>
                  </div>

                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Search type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={stealer_search_type}
                      onChange={(e) => set_stealer_search_type(e.target.value)}
                    >
                      <FormControlLabel
                        value={"contains"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="contains"
                      />
                      <FormControlLabel
                        value={"exact"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="exact"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                      sx={{ mt: 1 }}
                    >
                      Category
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={category_stealer}
                      onChange={(e) => set_category_stealer(e.target.value)}
                      sx={{ columnGap: 2 }}
                    >
                      {stealer_logs_categories.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          value={item.value}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={item.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {["file", "keyword", "file_extension"].includes(
                option_dropdown,
              ) && (
                <div className="dashboard_chart_filters_modal_keyword">
                  {option_dropdown === "keyword" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Field
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={field_filter}
                        onChange={(e) => set_field_filter(e.target.value)}
                      >
                        <FormControlLabel
                          value="None"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />

                        <FormControlLabel
                          value="conv_name"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Title"
                        />
                        <FormControlLabel
                          value="message"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Post"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  {notification_filters === "default" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Keyword
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                      >
                        <FormControlLabel
                          value="contains"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Contains"
                        />
                        <FormControlLabel
                          value="exact"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Exact"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={post_search_sub}
                      onChange={(e) => set_search_sub(e.target.value)}
                    >
                      <FormControlLabel
                        value="channel"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Channel"
                      />
                      <FormControlLabel
                        value="group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Group"
                      />
                      <FormControlLabel
                        value="Channel/Group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                  {option_dropdown === "keyword" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Spam
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={spam_filter}
                        onChange={(e) => set_spam_filter(e.target.value)}
                      >
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="OFF"
                        />
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="ON"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  <Categories
                    postCategory={postCategory}
                    setPostCategory={setPostCategory}
                    container_type={"row"}
                  />
                </div>
              )}
              {option_dropdown === "channel" ? (
                <FormControl className="dashboard_chart_radio_options">
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Filter type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={channel_filter_type}
                    onChange={(e) => set_channel_filter_type(e.target.value)}
                  >
                    <FormControlLabel
                      value="default"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Default"
                    />

                    <FormControlLabel
                      value="individual"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Individual"
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
              {option_dropdown === "tag" && (
                <div className="dashboard_chart_filters_modal_tag">
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <Box className="filter__category " id="scroll_filter">
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={notification_filters_tag}
                        onChange={(e) =>
                          set_notification_filters_tag(e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="bank_card"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Bank Card"
                        />
                        <FormControlLabel
                          value="email"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Email"
                        />
                        <FormControlLabel
                          value="ip_address"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Ip Address"
                        />
                        <FormControlLabel
                          value="crypto"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Crypto"
                        />
                        <FormControlLabel
                          value="domain"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Domain"
                        />
                        <FormControlLabel
                          value="phone"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Phone"
                        />
                        <FormControlLabel
                          value="bin"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Bin"
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>

                  {(notification_filters_tag === "bank_card" ||
                    notification_filters_tag === "bin") && (
                    <div>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Card Type
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {card_type_options.map((item, id) => {
                            let split_card_name = "";
                            if (item === "universal_air_travel_plan_card") {
                              split_card_name = "UATP";
                            } else if (item === "jcb_card") {
                              split_card_name = "JCB";
                            } else if (
                              ["amex_card", "mastercard", "visa_card"].includes(
                                item,
                              ) === false
                            ) {
                              split_card_name = item.replaceAll("card", "");
                            } else {
                              split_card_name = item;
                            }
                            split_card_name = split_card_name.replaceAll(
                              "_",
                              " ",
                            );
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_card_type}
                                    checked={card_type.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <span className="card_name">
                                    {split_card_name}
                                  </span>
                                }
                                key={id}
                              />
                            );
                          })}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_card_type}
                                checked={isAllselectedCardType}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Month
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {exp_month_options.map((item, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handle_change_exp_month}
                                  checked={exp_month.includes(item)}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={item}
                              key={i}
                            />
                          ))}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_exp_month}
                                checked={isAllselectedExpMonth}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Year
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {exp_year_options.map((item, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handle_change_exp_year}
                                  checked={exp_year.includes(item)}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={item}
                              key={i}
                            />
                          ))}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_exp_year}
                                checked={isAllselectedExpYear}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                    </div>
                  )}
                  {notification_filters_tag === "crypto" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Crypto Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {crypto_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_crypto_provider}
                                checked={crypto_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_crypto_provider}
                              checked={isAllselectedCryptoProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  {notification_filters_tag === "email" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Email Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {email_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_email_provider}
                                checked={email_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_email_provider}
                              checked={isAllselectedEmailProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  <Categories
                    postCategory={postCategory}
                    setPostCategory={setPostCategory}
                    container_type={"row"}
                  />
                </div>
              )}
              {option_dropdown === "channel" &&
              channel_filter_type === "individual" ? (
                <button type="submit" className="button_global_style">
                  Add
                </button>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </ClickAwayListener>
  );
}
UserDashboardInsertionFilters.propTypes = {
  set_open_modal: PropTypes.func,
  chart_type: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  stats_name: PropTypes.string,
  alert_type: PropTypes.string,
  edit_chart_query: PropTypes.string,
  set_edit_chart_query: PropTypes.func,
  alert_id: PropTypes.number,
  postCategory: PropTypes.array,
  setPostCategory: PropTypes.func,
  stealer_multiple_obj: PropTypes.object,
  set_stealer_multiple_obj: PropTypes.func,
  category_stealer: PropTypes.string,
  set_category_stealer: PropTypes.func,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  post_search_sub: PropTypes.string,
  set_search_sub: PropTypes.func,
  query_filter: PropTypes.string,
  set_query_filter: PropTypes.func,
  spam_filter: PropTypes.bool,
  set_spam_filter: PropTypes.func,
  field_filter: PropTypes.string,
  set_field_filter: PropTypes.func,
  card_type: PropTypes.array,
  set_card_type: PropTypes.func,
  crypto_provider: PropTypes.array,
  set_crypto_provider: PropTypes.func,
  email_provider: PropTypes.array,
  set_email_provider: PropTypes.func,
  exp_year: PropTypes.array,
  set_exp_year: PropTypes.func,
  exp_month: PropTypes.array,
  set_exp_month: PropTypes.func,
  chart_value: PropTypes.string,
  stealer_search_type: PropTypes.string,
  set_stealer_search_type: PropTypes.func,
  option_dropdown: PropTypes.string,
  notification_filters_tag: PropTypes.string,
  set_notification_filters_tag: PropTypes.func,
  stealer_filter_type: PropTypes.string,
  set_stealer_filter_type: PropTypes.func,
  channels_list: PropTypes.array,
  set_channels_list: PropTypes.func,
  handle_dashboard_insertion: PropTypes.func,
  channel_filter_type: PropTypes.string,
  set_channel_filter_type: PropTypes.func,
  chart_pref: PropTypes.string,
  set_chart_pref: PropTypes.func,
  post_category_stealer: PropTypes.string,
  set_post_category_stealer: PropTypes.func,
  notification_filters: PropTypes.string,
  set_notification_filters: PropTypes.string,
};

function UserDashboardInsertionFiltersUpdate({
  set_open_modal,
  post_category_update,
  set_post_category_update,
  modal_data,
  set_modal_data,
  set_update_loader,
  set_user_dashboard_data,
  dashboard_mode,
}) {
  const token = handle_session_get("Token");
  const [search_val, set_search_val] = useState("");
  const [search_error, set_search_error] = useState("");
  const [search_data, set_search_data] = useState(null);
  const [stat_loading, set_stat_loading] = useState(false);
  const {
    exp_year,
    exp_month,
    card_type,
    crypto_provider,
    email_provider,
    edit_chart_query,
    option_dropdown,
    category_stealer,
    keyword,
    post_search_sub,
    field_filter,
    stealer_multiple_obj,
    spam_filter,
    notification_filters_tag,
    notification_filters,
    stealer_filter_type,
    stealer_search_type,
    chart_pref,
    channels_list,
    channel_filter_type,
  } = modal_data;
  const handle_update_modal_state = (key, value) => {
    set_modal_data((prev_state) => ({
      ...prev_state,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (option_dropdown === "channel" && channel_filter_type === "default") {
      set_search_val(edit_chart_query);
    }
  }, []);
  const isAllselectedExpYear =
    exp_year_options.length > 0 && exp_year.length === exp_year_options.length;
  const isAllselectedExpMonth =
    exp_month_options.length > 0 &&
    exp_month.length === exp_month_options.length;
  const isAllselectedCardType =
    card_type_options.length > 0 &&
    card_type.length === card_type_options.length;
  const isAllselectedCryptoProvider =
    crypto_options.length > 0 &&
    crypto_provider.length === crypto_options.length;
  const isAllselectedEmailProvider =
    email_options.length > 0 && email_provider.length === email_options.length;

  const handle_change_stealer_input = (event) => {
    const { id } = event.target;
    const { value } = event.target;
    set_modal_data((prev) => ({
      ...prev,
      stealer_multiple_obj: { ...prev.stealer_multiple_obj, [id]: value },
    }));
  };
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      handle_update_modal_state(
        "card_type",
        card_type.length === card_type_options.length ? [] : card_type_options,
      );
    } else {
      const list_card_type = [...card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      handle_update_modal_state("card_type", list_card_type);
    }
  };
  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      handle_update_modal_state(
        "crypto_provider",
        crypto_provider.length === crypto_options.length ? [] : crypto_options,
      );
    } else {
      const list_crypto_provider = [...crypto_provider];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      handle_update_modal_state("crypto_provider", list_crypto_provider);
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      handle_update_modal_state(
        "email_provider",
        email_provider.length === email_options.length ? [] : email_options,
      );
    } else {
      const list_email_provider = [...email_provider];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      handle_update_modal_state("email_provider", list_email_provider);
    }
  };
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      handle_update_modal_state(
        "exp_year",
        exp_year.length === exp_year_options.length ? [] : exp_year_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      handle_update_modal_state("exp_year", list_exp_year);
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      handle_update_modal_state(
        "exp_month",
        exp_month.length === exp_month_options.length ? [] : exp_month_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      handle_update_modal_state("exp_month", list_exp_month);
    }
  };
  const val_change = (e) => {
    set_search_val(e.target.value);
    if (e.target.value.length >= 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_search_data(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_search_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    }
  };

  const handle_add_channels_list = (added_channel) => {
    handle_update_modal_state("channels_list", [
      ...modal_data["channels_list"],
      added_channel,
    ]);
  };
  const handle_delete_channels = (name) => {
    const filter_channels = modal_data["channels_list"].filter(
      (item) => item !== name,
    );
    handle_update_modal_state("channels_list", filter_channels);
  };
  const channels_list_chips = channels_list.map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_channels(item)}
      color="success"
      key={index}
    />
  ));
  const handle_check_options_length = (options, selected) => {
    try {
      if (options.length === selected.length) return true;
      return false;
    } catch {
      return false;
    }
  };

  const handle_update_user_dashboard = (channel_name) => {
    let isSubmitBlocked = false;
    set_update_loader(true);
    const alert_type = modal_data.option_dropdown;
    const alert_id = modal_data.all_data.id;
    const filter_stealer_multiple_obj = {};
    for (const key in stealer_multiple_obj) {
      if (Object.prototype.hasOwnProperty.call(stealer_multiple_obj, key)) {
        filter_stealer_multiple_obj[key] =
          stealer_multiple_obj[key].length === 0
            ? null
            : stealer_multiple_obj[key];
      }
    }

    const chart_filter_obj = {
      selectCategory: ["keyword", "file", "tag"].includes(alert_type)
        ? handle_check_category_length(post_category_update) === true
          ? "all"
          : post_category_update
        : "all",
      value:
        option_dropdown === "channel" &&
        channel_filter_type === "default" &&
        channel_name
          ? channel_name
          : option_dropdown === "channel" && channel_filter_type === "default"
          ? search_val
          : edit_chart_query,
      dashboard_type: alert_type,
      dashboard_filter:
        alert_type === "channel"
          ? channel_filter_type === "individual"
            ? "nested"
            : "default"
          : notification_filters,
      dashboard_preference: chart_pref,
      is_enabled: true,
      generate_stats: false,
    };
    if (dashboard_mode === "admin") {
      const user_id = modal_data.all_data?.user_obj?.userid ?? null;
      chart_filter_obj["ingest_mode"] = "admin";
      chart_filter_obj["selected_user"] = user_id;
    }
    if (["tag", "stealer log"].includes(alert_type)) {
      chart_filter_obj["search_mode"] =
        alert_type === "tag" ? notification_filters_tag : stealer_filter_type;
    }
    if (["keyword", "file"].includes(alert_type)) {
      chart_filter_obj["telegram_obj"] = {
        field_filter:
          alert_type === "keyword"
            ? field_filter === "None"
              ? null
              : field_filter
            : alert_type === "file"
            ? "filename"
            : "conv_name",
        spam:
          alert_type === "keyword"
            ? ["True", "true", true].includes(spam_filter)
            : false,
        search_type: keyword,
        search_filter: post_search_sub,
      };
    } else if (alert_type === "tag") {
      const tag_search_mode = notification_filters_tag;
      chart_filter_obj["tag_obj"] = {
        exp_year: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(exp_year_options, exp_year) === true
            ? "all"
            : exp_year
          : null,
        exp_month: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(exp_month_options, exp_month) === true
            ? "all"
            : exp_month
          : null,
        bank_card_type: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(card_type_options, card_type) === true
            ? "all"
            : card_type
          : null,
        email_type:
          tag_search_mode === "email"
            ? handle_check_options_length(email_options, email_provider) ===
              true
              ? "all"
              : email_provider
            : null,
        crypto_type:
          tag_search_mode === "crypto"
            ? handle_check_options_length(crypto_options, crypto_provider) ===
              true
              ? "all"
              : crypto_provider
            : null,
      };
    } else if (alert_type === "stealer log") {
      chart_filter_obj["stealer_obj"] = {
        ...filter_stealer_multiple_obj,
        search_type: stealer_search_type,
        stealer_category: category_stealer === "off" ? null : category_stealer,
      };
    } else if (option_dropdown === "channel") {
      chart_filter_obj["channel_obj"] = {
        channel_name: channel_filter_type === "individual" ? channels_list : [],
      };
    }
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .put(
          `https://api.recordedtelegram.com/user_dashboard/${alert_id}`,
          chart_filter_obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          const updated_data = res.data.data;
          set_user_dashboard_data((prev) => {
            const clone_user_data = [...prev];
            const index = clone_user_data.findIndex(
              (item) => item.id === updated_data.id,
            );
            if (index > -1) {
              clone_user_data.splice(index, 1, updated_data);
            }
            return clone_user_data;
          });
          set_update_loader(false);
          handle_success_message("Dashboard updated successfully");
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_update_loader(false);
          handle_error_message(err.response.data.errormsg);
          isSubmitBlocked = false;
        });
    }
  };
  return (
    <ClickAwayListener onClickAway={() => set_open_modal(false)}>
      <div className="dashboard_chart_filters_modal scroll-custom user_dashboard_insertion_filters_update_container">
        <Formik
          initialValues={{
            email: "",
            domain: "",
            password: "",
            country_code: "",
            ip_address: "",
            zip: "",
            location: "",
          }}
          validate={() => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              if (
                option_dropdown === "channel" &&
                channel_filter_type === "default" &&
                search_val.length === 0
              )
                return;
              handle_update_user_dashboard();
              set_open_modal(false);
            });
          }}
        >
          {({ handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <h5 className="dashbord_chart_filter_title">Filter your data</h5>
              <>
                {option_dropdown === "channel" ? (
                  <>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <input
                        type="text"
                        id="email"
                        name="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          val_change(event);
                        }}
                        onBlur={handleBlur}
                        className="chart_edit_value_input_box w-50"
                        value={search_val}
                        placeholder="enter channel/group"
                      />
                      {search_val.length >= 2 &&
                      option_dropdown === "channel" &&
                      search_data !== null ? (
                        <div
                          className="autocom-box channel_list_container mt-3"
                          style={{ width: "90%", left: "4%", top: "60%" }}
                        >
                          {stat_loading ? (
                            <RollerAnimation />
                          ) : search_error ? (
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "larger",
                                color: "white",
                                marginTop: "10%",
                              }}
                            >
                              {search_error}
                            </div>
                          ) : search_data !== null &&
                            search_data.length === 0 ? (
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "larger",
                                color: "white",
                                marginTop: "10%",
                              }}
                            >
                              No Data to display
                            </div>
                          ) : (
                            <>
                              <h4
                                style={{
                                  paddingLeft: "1rem",
                                  marginLeft: "15px",
                                  marginTop: "5px",
                                  fontSize: "18px",
                                  color: "whitesmoke",
                                }}
                              >
                                List Of Channels
                              </h4>
                              <ul>
                                {search_data !== null &&
                                  search_data.map((val, id) => (
                                    <li key={id}>
                                      <div className="row">
                                        <div
                                          className="col-md-8"
                                          style={{ textAlign: "left" }}
                                        >
                                          {" "}
                                          <img
                                            style={{
                                              width: "36px",
                                              height: "36px",
                                              padding: "2px",
                                              border: "2px solid #f4f4f4",
                                            }}
                                            className="img-fluid rounded-circle"
                                            src={
                                              val.channel_id !== "None"
                                                ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                            }
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                            }}
                                            alt="User"
                                          />
                                          <span
                                            className="channels_list_map"
                                            style={{
                                              marginLeft: "10px",
                                              color: "#dcf3ff",
                                            }}
                                          >
                                            {val.channel_name}
                                          </span>
                                        </div>
                                        <div
                                          className="col-md-4"
                                          style={{
                                            textAlign: "right",
                                            marginTop: "5px",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            style={{
                                              borderRadius: "40px",
                                              background: "#d03940",
                                              color: "white",
                                              lineHeight: "1",
                                            }}
                                            onClick={() => {
                                              if (
                                                channel_filter_type ===
                                                "individual"
                                              ) {
                                                handle_add_channels_list(
                                                  val.channel_name,
                                                );
                                                set_search_val("");
                                              } else {
                                                handle_update_user_dashboard(
                                                  val.channel_name,
                                                );
                                                set_search_val("");
                                                set_open_modal(false);
                                              }
                                            }}
                                            className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </>
                          )}
                        </div>
                      ) : null}
                    </div>
                    {channel_filter_type === "individual" &&
                      channels_list_chips}
                  </>
                ) : null}
                {option_dropdown !== "channel" ||
                (option_dropdown === "channel" &&
                  channel_filter_type === "individual") ? (
                  <Stack direction="row" justifyContent="flex-start">
                    <input
                      type="text"
                      id="email"
                      name="text"
                      onChange={(event) => {
                        event.preventDefault();
                        handleChange(event);
                        handle_update_modal_state(
                          "edit_chart_query",
                          event.target.value,
                        );
                      }}
                      onBlur={handleBlur}
                      className="chart_edit_value_input_box w-50"
                      value={edit_chart_query}
                      placeholder="enter keyword"
                    />
                  </Stack>
                ) : null}
              </>
              {option_dropdown !== "channel" && option_dropdown !== "tag" && (
                <FormControl className="dashboard_chart_radio_options">
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Filters
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={notification_filters}
                    onChange={(e) =>
                      handle_update_modal_state(
                        "notification_filters",
                        e.target.value,
                      )
                    }
                  >
                    <FormControlLabel
                      value="default"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Default"
                    />
                    <FormControlLabel
                      value="regex"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Regex"
                    />
                    {!["tag", "stealer log"].includes(option_dropdown) && (
                      <FormControlLabel
                        value="logical"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Logical"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              )}
              {option_dropdown === "channel" && (
                <FormControl className="dashboard_chart_radio_options">
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Filter
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={channel_filter_type}
                    onChange={(e) => {
                      handle_update_modal_state(
                        "channel_filter_type",
                        e.target.value,
                      );
                      if (e.target.value === "individual") {
                        handle_update_modal_state("edit_chart_query", "");
                      }
                    }}
                  >
                    <FormControlLabel
                      value="default"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Default"
                    />

                    <FormControlLabel
                      value="individual"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Individual"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <FormControl className="dashboard_chart_radio_options">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Preference
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={chart_pref}
                  onChange={(e) =>
                    handle_update_modal_state("chart_pref", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="user"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="User"
                  />
                  <FormControlLabel
                    value="company"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Company"
                  />
                </RadioGroup>
              </FormControl>
              {option_dropdown === "stealer log" && (
                <div className="dashboard_chart_filters_modal_stealerslogs">
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      row
                      value={stealer_filter_type}
                      onChange={(e) =>
                        handle_update_modal_state(
                          "stealer_filter_type",
                          e.target.value,
                        )
                      }
                    >
                      <FormControlLabel
                        value="email"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Username/Email"
                      />
                      <FormControlLabel
                        value="domain"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Domain"
                      />
                      <FormControlLabel
                        value="password"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Password"
                      />
                      <FormControlLabel
                        value="country_code"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Country Code"
                      />
                      <FormControlLabel
                        value="ip_address"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="IP"
                      />
                      <FormControlLabel
                        value="zip"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Zip"
                      />
                      <FormControlLabel
                        value="location"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Location"
                      />
                      <FormControlLabel
                        value="device_id"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="MID/HWID/GUID"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div
                    className="modal_stealer_logs_multiple_search_input_container"
                    style={{ marginTop: "5px" }}
                  >
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="email">
                        Email / Username
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.email}
                        placeholder="enter email/username"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="domain">
                        Domain
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="domain"
                        name="domain"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.domain}
                        placeholder="enter domain"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="password"
                        name="password"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.password}
                        placeholder="enter password"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="country_code"
                      >
                        Country Code
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="country_code"
                        name="country_code"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.country_code}
                        placeholder="enter country code"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="ip_address"
                      >
                        IP Address
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="ip_address"
                        name="ip_address"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.ip_address}
                        placeholder="ente ip address"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="zip">
                        Zip
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="zip"
                        name="zip"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.zip}
                        placeholder="enter zip"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="location">
                        Location
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="location"
                        name="location"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.location}
                        placeholder="enter location"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="device_id">
                        MID/HWID/GUID
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="device_id"
                        name="device_id"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.device_id}
                        placeholder="enter MID/HWID/GUID"
                      />
                    </div>
                  </div>

                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Search type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={stealer_search_type}
                      onChange={(e) =>
                        handle_update_modal_state(
                          "stealer_search_type",
                          e.target.value,
                        )
                      }
                    >
                      <FormControlLabel
                        value={"contains"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="contains"
                      />
                      <FormControlLabel
                        value={"exact"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="exact"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                      sx={{ mt: 1 }}
                    >
                      Category
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={category_stealer}
                      onChange={(e) =>
                        handle_update_modal_state(
                          "category_stealer",
                          e.target.value,
                        )
                      }
                      sx={{ columnGap: 2 }}
                    >
                      {stealer_logs_categories.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          value={item.value}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={item.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {(option_dropdown === "file" ||
                option_dropdown === "keyword") && (
                <div className="dashboard_chart_filters_modal_keyword">
                  {option_dropdown === "keyword" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Field
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={field_filter}
                        onChange={(e) =>
                          handle_update_modal_state(
                            "field_filter",
                            e.target.value,
                          )
                        }
                      >
                        <FormControlLabel
                          value="None"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />

                        <FormControlLabel
                          value="conv_name"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Title"
                        />
                        <FormControlLabel
                          value="message"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Post"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  {notification_filters === "default" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Keyword
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={keyword}
                        onChange={(e) =>
                          handle_update_modal_state("keyword", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="contains"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Contains"
                        />
                        <FormControlLabel
                          value="exact"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Exact"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={post_search_sub}
                      onChange={(e) =>
                        handle_update_modal_state(
                          "post_search_sub",
                          e.target.value,
                        )
                      }
                    >
                      <FormControlLabel
                        value="channel"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Channel"
                      />
                      <FormControlLabel
                        value="group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Group"
                      />
                      <FormControlLabel
                        value="Channel/Group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                  {option_dropdown === "keyword" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Spam
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={spam_filter}
                        onChange={(e) =>
                          handle_update_modal_state(
                            "spam_filter",
                            e.target.value,
                          )
                        }
                      >
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="OFF"
                        />
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="ON"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  <Categories
                    postCategory={post_category_update}
                    setPostCategory={set_post_category_update}
                    container_type={"row"}
                  />
                </div>
              )}
              {option_dropdown === "tag" && (
                <div className="dashboard_chart_filters_modal_tag">
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <Box className="filter__category " id="scroll_filter">
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={notification_filters_tag}
                        onChange={(e) =>
                          handle_update_modal_state(
                            "notification_filters_tag",
                            e.target.value,
                          )
                        }
                      >
                        <FormControlLabel
                          value="bank_card"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Bank Card"
                        />
                        <FormControlLabel
                          value="email"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Email"
                        />
                        <FormControlLabel
                          value="ip_address"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Ip Address"
                        />
                        <FormControlLabel
                          value="crypto"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Crypto"
                        />
                        <FormControlLabel
                          value="domain"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Domain"
                        />
                        <FormControlLabel
                          value="phone"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Phone"
                        />
                        <FormControlLabel
                          value="bin"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Bin"
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>

                  {(notification_filters_tag === "bank_card" ||
                    notification_filters_tag === "bin") && (
                    <div>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Card Type
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {card_type_options.map((item, id) => {
                            let split_card_name = "";
                            if (item === "universal_air_travel_plan_card") {
                              split_card_name = "UATP";
                            } else if (item === "jcb_card") {
                              split_card_name = "JCB";
                            } else if (
                              ["amex_card", "mastercard", "visa_card"].includes(
                                item,
                              ) === false
                            ) {
                              split_card_name = item.replaceAll("card", "");
                            } else {
                              split_card_name = item;
                            }
                            split_card_name = split_card_name.replaceAll(
                              "_",
                              " ",
                            );
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_card_type}
                                    checked={card_type.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <span className="card_name">
                                    {split_card_name}
                                  </span>
                                }
                                key={id}
                              />
                            );
                          })}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_card_type}
                                checked={isAllselectedCardType}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Month
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {exp_month_options.map((item, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handle_change_exp_month}
                                  checked={exp_month.includes(item)}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={item}
                              key={i}
                            />
                          ))}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_exp_month}
                                checked={isAllselectedExpMonth}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                      <FormControl className="dashboard_chart_radio_options">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Year
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {exp_year_options.map((item, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handle_change_exp_year}
                                  checked={exp_year.includes(item)}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={item}
                              key={i}
                            />
                          ))}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_exp_year}
                                checked={isAllselectedExpYear}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                    </div>
                  )}
                  {notification_filters_tag === "crypto" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Crypto Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {crypto_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_crypto_provider}
                                checked={crypto_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_crypto_provider}
                              checked={isAllselectedCryptoProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  {notification_filters_tag === "email" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Email Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {email_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_email_provider}
                                checked={email_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_email_provider}
                              checked={isAllselectedEmailProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  <Categories
                    postCategory={post_category_update}
                    setPostCategory={set_post_category_update}
                    container_type={"row"}
                  />
                </div>
              )}
              <button type="submit" className="button_global_style">
                Update
              </button>
            </form>
          )}
        </Formik>
      </div>
    </ClickAwayListener>
  );
}
UserDashboardInsertionFiltersUpdate.propTypes = {
  modal_data: PropTypes.object,
  set_modal_data: PropTypes.func,
  set_open_modal: PropTypes.func,
  set_update_loader: PropTypes.func,
  post_category_update: PropTypes.array,
  set_post_category_update: PropTypes.func,
  set_user_dashboard_data: PropTypes.func,
  dashboard_mode: PropTypes.string,
};
export default memo(UserDashboardInsertionFilters);
export { UserDashboardInsertionFiltersUpdate };
