import React, { useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Badge from "@mui/material/Badge";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import { handle_disable_radio_btn } from "../../../utils/SessionStorage/handle_session";
import { filter_context } from "../searchbar/Searchbar";
import Datepicker from "../sections/DatePicker/Datepicker";
import { stealer_logs_categories } from "../../../utils/Globals/global_variables";
import "../../../css/Filters/filters.css";

function FiltersStealerLogs() {
  const {
    setShowFilters,
    display_type_stealer,
    set_display_type_stealer,
    max_result_stealer,
    set_max_result_stealer,
    search_type_stealer,
    set_search_type_stealer,
    stealer_date_from,
    set_stealer_date_from,
    stealer_date_to,
    set_stealer_date_to,
    stealer_filter_type,
    set_stealer_filter_type,
    stealer_date_log,
    set_stealer_date_log,
    stealer_auto_fields,
    set_stealer_auto_fields,
    stealer_filters,
    post_category_stealer,
    set_post_category_stealer,
  } = useContext(filter_context)["stealer_filters"];
  // Click outside function
  const handleClickAway = () => {
    setShowFilters(false);
  };

  const handle_stealer_search_type = (event) => {
    set_search_type_stealer(event.target.value);
  };
  const handle_stealer_filter_type = (event) => {
    set_stealer_filter_type(event.target.value);
    set_stealer_auto_fields(true);
  };

  const handle_stealer_display_type = (event) => {
    set_display_type_stealer(event.target.value);
  };

  const handle_max_result_stealer = (event) => {
    set_max_result_stealer(event.target.value);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="filters__main filters_breach filters_card">
        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            Search Type
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={search_type_stealer}
            onChange={handle_stealer_search_type}
            sx={{ columnGap: 2 }}
          >
            <FormControlLabel
              value="contains"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="Contains"
            />
            <FormControlLabel
              value="exact"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="Exact"
            />
            <FormControlLabel
              value="regex"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="Regex"
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            Filter Type
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={stealer_filter_type}
            onChange={handle_stealer_filter_type}
            sx={{ columnGap: 2 }}
          >
            <FormControlLabel
              value="default"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="default"
            />
            <FormControlLabel
              value="individual"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="Custom"
            />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            View Type
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={display_type_stealer}
            onChange={handle_stealer_display_type}
            sx={{ columnGap: 2 }}
          >
            <FormControlLabel
              value="table"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="Table"
            />
            <FormControlLabel
              value="json"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="JSON"
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            Category
          </FormLabel>
          <Box
            className="filter__category"
            sx={{ height: "66%", paddingLeft: "1px" }}
          >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={post_category_stealer}
              onChange={(e) => set_post_category_stealer(e.target.value)}
              sx={{ columnGap: 2 }}
            >
              {stealer_logs_categories.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label={item.name}
                />
              ))}
            </RadioGroup>
          </Box>
        </FormControl>

        <FormControl>
          <div className="dateRangePicker">
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Date
            </FormLabel>
            <Datepicker
              date_from={stealer_date_from}
              set_date_from={set_stealer_date_from}
              date_to={stealer_date_to}
              set_date_to={set_stealer_date_to}
            />
          </div>
        </FormControl>
        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            Max result
          </FormLabel>
          <Box id="scroll_filter">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={max_result_stealer}
              onChange={handle_max_result_stealer}
              sx={{ columnGap: 2 }}
            >
              <FormControlLabel
                value={50}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="50"
              />

              <FormControlLabel
                value={100}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="100"
              />

              <FormControlLabel
                value={500}
                control={
                  <Radio
                    disabled={handle_disable_radio_btn()}
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={
                  <Badge color="secondary" badgeContent="premium">
                    <span>500</span>
                  </Badge>
                }
              />

              <FormControlLabel
                value={1000}
                control={
                  <Radio
                    disabled={handle_disable_radio_btn()}
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={
                  <Badge color="secondary" badgeContent="premium">
                    <span>1000</span>
                  </Badge>
                }
              />
            </RadioGroup>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            Log Date
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={stealer_date_log}
            onChange={(e) => set_stealer_date_log(e.target.value)}
            sx={{ columnGap: 2 }}
          >
            <FormControlLabel
              value={false}
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="OFF"
            />
            <FormControlLabel
              value
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="ON"
            />
          </RadioGroup>
        </FormControl>
        {stealer_filters === "email" ? (
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Auto Fills
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={stealer_auto_fields}
              onChange={(e) => set_stealer_auto_fields(e.target.value)}
              sx={{ columnGap: 2 }}
            >
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Exclude"
              />
              <FormControlLabel
                value
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Include"
              />
            </RadioGroup>
          </FormControl>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}
export default FiltersStealerLogs;
