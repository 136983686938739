import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import UserDashboardInsertion from "./UserDashboardInsertion";
function DashboardInsertionModal({ openModal, setOpenModal }) {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="seemore_container_modal">
          <Box className="container_modal_child">
            <div className="modal-heading-parent-closeBtn">
              <button
                className="modal-heading-closeBtn"
                onClick={() => setOpenModal(false)}
              >
                <CancelIcon
                  sx={{ color: "#eb0000 ", cursor: "pointer", mr: 1 }}
                />
              </button>
            </div>
            <UserDashboardInsertion dashboard_mode={"user"} />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

DashboardInsertionModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export default DashboardInsertionModal;
