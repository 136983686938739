import "./App.css";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
// import Signup from './components/Signup';
import React from "react";
import Login from "./components/Login";
import Userprof from "./components/Admin/Userprof";
import "./customer.css";
import Admindashboard from "./components/Admin/Admindashboard";
import Adminusers from "./components/Admin/Adminusers";
import Adminscraper from "./components/Admin/Adminscraper";
import AddCredentialRegex from "./components/Admin/AddCredentialRegex";
import AddCredentialinsustryFunc from "./components/Admin/AddCredentialinsustryFunc";
import AddDashboardToUser from "./components/Admin/AddDashboardToUser";
// import AddCredentialAlert from "./components/Admin/AddCredentialAlert";
import AddMultipleAcct from "./components/Admin/AddMultipleAcct";
import Pagestats from "./components/Pagestats";
import Userchannel from "./components/Userchannel";
import CompanyDashboard from "./TableUi/components/company_dashboard/CompanyDashboard";
import CompanyStatsFunc from "./TableUi/components/company_dashboard/CompanyStatsFunc";
import Mainnotification from "./components/Mainnotification";
import Userprofile from "./components/Userprofile";
import AutoCredintial from "./components/Admin/Autocredentials";
import Mainuserstats from "./components/Mainuserstats";
import IndexTable from "./TableUi/IndexTable";
import Home from "./TableUi/components/home/Home";
import DisplayFlexFunction from "./TableUi/components/display_flex/DisplayFlexFunction";
import MainBlockList from "./components/MainBlockList";
import MarkedChannelsFunc from "./TableUi/components/markedchannels/MarkedChannelsFunc";
import AdminSearchUser from "./components/Admin/AdminSearchUser";
import ChannelScrapeRequest from "./TableUi/components/modalContainer/ChannelScrapeRequest";
import { useSelector } from "react-redux";

function App() {
  const open_modal_scrape_channel = useSelector(
    (state) => state.filter_reducer.channel_scrape_modal,
  );
  return (
    <>
      <Router>
        <Switch>
          {/* <Route exact path='/signup'>
              <div className="App">
                <Signup />
              </div>
            </Route> */}
          <Route exact path="/">
            <div className="App">
              <Login />
            </div>
          </Route>
          <Route exact path="/login">
            <div className="App">
              <Login />
            </div>
          </Route>
          <Route exact path="/notification">
            <div className="App">
              <Mainnotification />
            </div>
          </Route>
          <Route exact path="/block-channel">
            <div className="App">
              <MainBlockList />
            </div>
          </Route>
          <Route exact path="/channel-preference">
            <div className="App">
              <MarkedChannelsFunc />
            </div>
          </Route>
          <Route exact path="/company-dashboard">
            <div className="App">
              <CompanyDashboard />
            </div>
          </Route>
          <Route exact path="/company-statspage">
            <div className="App">
              <CompanyStatsFunc />
            </div>
          </Route>
          <Route exact path="/dashboard">
            <div className="App">
              <Home />
            </div>
          </Route>
          <Route exact path="/indv-page">
            <div className="App">
              <Userchannel />
            </div>
          </Route>
          <Route exact path="/page">
            <div className="App">
              <Pagestats />
            </div>
          </Route>
          <Route exact path="/channel">
            <div className="App">
              <DisplayFlexFunction />
            </div>
          </Route>
          <Route exact path="/admin">
            <Adminusers />
          </Route>
          <Route exact path="/admin/create-multiple-accounts">
            <AddMultipleAcct />
          </Route>
          <Route exact path="/users">
            <div className="App">
              <Mainuserstats />
            </div>
          </Route>
          <Route exact path="/prof">
            <div className="App">
              <Userprof />
            </div>
          </Route>
          <Route exact path="/admin/dashboard">
            <div className="App">
              <Admindashboard />
            </div>
          </Route>
          <Route exact path="/admin/cred">
            <div className="App">
              <AutoCredintial />
            </div>
          </Route>
          <Route exact path="/admin/user-search">
            <div className="App">
              <AdminSearchUser />
            </div>
          </Route>
          <Route exact path="/admin/add-regex">
            <div className="App">
              <AddCredentialRegex />
            </div>
          </Route>
          <Route exact path="/admin/add-industry">
            <div className="App">
              <AddCredentialinsustryFunc />
            </div>
          </Route>
          <Route exact path="/admin/add-alert">
            <div className="App">
              <AddDashboardToUser />
            </div>
          </Route>
          <Route exact path="/admin/scraper">
            <div className="App">
              <Adminscraper />
            </div>
          </Route>
          <Route exact path="/userprof">
            <div className="App">
              <Userprofile />
            </div>
          </Route>
          <Route exact path="/beta">
            <div className="App">
              <IndexTable />
            </div>
          </Route>
        </Switch>
      </Router>
      {open_modal_scrape_channel ? <ChannelScrapeRequest /> : null}
    </>
  );
}

export default App;
