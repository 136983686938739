import React, { useState, useContext, memo } from "react";
import { Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DotsLoading } from "../loader/Loader";
import { chart_filter_context } from "./CompanyStatsFunc";
import TagSearchTable from "../tabledata/TagSearchTable";
import { ThreeCircles } from "react-loader-spinner";
import { handle_add_query_to_dashboard } from "./companyglobalfunc";
import DashboardTableFilters from "../modalContainer/DashboardTableFilters";
import { dashbaord_search_context } from "./UniversalTableDashboard";
import { options_post_category } from "../../../Assets/globalfunctions";
import {
  card_type_options,
  crypto_options,
  email_options,
  exp_year_options,
  exp_month_options,
} from "../../../utils/Globals/global_variables";
import { handle_determine_dashboard_tag_search_subtype } from "./companyglobalfunc";

function DashboardTagTable() {
  const {
    dashboard_chart_list_array,
    set_dashboard_chart_list_array,
    get_individual_chart_alert_stats,
  } = useContext(chart_filter_context);

  const {
    tag_data,
    getSearchedData,
    table_type,
    set_table_type,
    add_query_to_dashboard_loading,
    set_add_query_to_dashboard_loading,
  } = useContext(dashbaord_search_context);

  const [open_dash_table_filters, set_open_dash_table_filters] =
    useState(false);
  const [postCategory, setPostCategory] = useState(options_post_category);
  const [card_type, set_card_type] = useState(card_type_options);
  const [crypto_provider, set_crypto_provider] = useState(crypto_options);
  const [email_provider, set_email_provider] = useState(email_options);
  const [exp_year, set_exp_year] = useState(exp_year_options);
  const [exp_month, set_exp_month] = useState(exp_month_options);

  const handle_dashboard_access_version_2 = (new_chart_list) => {
    const list_of_previous_chart_id = [...dashboard_chart_list_array].map(
      (item) => item.id,
    );
    const dashboard_chart_list = [...new_chart_list];
    const filter_new_id = dashboard_chart_list.filter(
      (item) =>
        list_of_previous_chart_id.includes(item.id) === false &&
        item.is_enabled,
    );
    if (Array.isArray(filter_new_id) && filter_new_id.length > 0) {
      const clone_obj = { ...filter_new_id[0] };
      const new_obj = {
        dashboard_filter: clone_obj["dashboard_filter"],
        dashboard_type: clone_obj["dashboard_type"],
        id: clone_obj["id"],
        keyword: clone_obj["value"],
      };
      get_individual_chart_alert_stats(new_obj);
      setTimeout(() => set_dashboard_chart_list_array(dashboard_chart_list));
    }
  };
  const template_card_filters =
    handle_determine_dashboard_tag_search_subtype(getSearchedData);
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "97%" }}
      >
        <h4 className="dashboard_search_table_heading">Tag search</h4>
        <Stack direction={"row"} columnGap={2} alignItems="baseline">
          <button
            className="dashbaord_search_chart_filter_btn"
            onClick={() => {
              set_open_dash_table_filters(true);
              set_table_type("tag");
            }}
          >
            <FilterListIcon
              sx={{ color: "rgb(144, 202, 249)" }}
              fontSize="large"
            />
          </button>
          {add_query_to_dashboard_loading.tag_loading ? (
            <DotsLoading />
          ) : (
            <button
              className="dashbaord_search_chart_add_btn"
              onClick={() =>
                handle_add_query_to_dashboard(
                  getSearchedData,
                  "tag",
                  set_add_query_to_dashboard_loading,
                  handle_dashboard_access_version_2,
                  template_card_filters,
                )
              }
            >
              Add to dashboard
            </button>
          )}
        </Stack>
      </Stack>
      <div style={{ position: "relative" }}>
        <div className="dashbaord_table_telegram_search scroll-custom">
          {tag_data.loading === true ? (
            <div className="spinner">
              <ThreeCircles
                color="#28cab3"
                height={60}
                width={60}
                ariaLabel="three-circles-rotating"
              />
            </div>
          ) : tag_data.error ? (
            <div className="main__table no_data">{tag_data.error}</div>
          ) : tag_data.data.length === 0 ? (
            <div className="main__table no_data">No data to display</div>
          ) : (
            <TagSearchTable
              Data={tag_data.data}
              getSearchedData={getSearchedData}
              template_card_filters={handle_determine_dashboard_tag_search_subtype(
                getSearchedData,
              )}
              get_tag_data={() => {}}
              total_data={tag_data.data.length > 0 ? tag_data.total_data : 0}
              tag_rate_limit={tag_data.rate_limit}
              file_id={tag_data.file_id}
              loadmore_loading={false}
              table_mode="dashboard_search_table"
            />
          )}
        </div>
        {table_type === "tag" && open_dash_table_filters ? (
          <DashboardTableFilters
            set_open_modal={set_open_dash_table_filters}
            graph_type={handle_determine_dashboard_tag_search_subtype(
              getSearchedData,
            )}
            postCategory={postCategory}
            setPostCategory={setPostCategory}
            card_type={card_type}
            set_card_type={set_card_type}
            crypto_provider={crypto_provider}
            set_crypto_provider={set_crypto_provider}
            email_provider={email_provider}
            set_email_provider={set_email_provider}
            exp_year={exp_year}
            set_exp_year={set_exp_year}
            exp_month={exp_month}
            set_exp_month={set_exp_month}
          />
        ) : null}
      </div>
    </div>
  );
}

export default memo(DashboardTagTable);
