import React, { useEffect } from "react";
import { Stack, GlobalStyles } from "@mui/material";
import { Link, Redirect } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import {
  handle_session_get,
  handle_clear_localStorage,
} from "../../../utils/SessionStorage/handle_session";
import { useDispatch } from "react-redux";
import {
  handle_store_url_location,
  handle_open_channel_scrape_modal,
} from "../../../actions/filterAction";
import "../../../css/Navbar/navbar.css";

function Usernavstats() {
  useEffect(() => {
    if (handle_session_get("Token") === null) {
      return;
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const customer_name = handle_session_get("User");
  const account_type = handle_session_get("Acc_typ");

  if (handle_session_get("Token") === null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <div className="navbar__main">
      <GlobalStyles
        styles={{
          ".MuiDataGrid-menuList": {
            backgroundColor: "#394e5a",
          },
          ".MuiMenu-list ": {
            backgroundColor: "#394e5a",
          },
          ".MuiDataGrid-menuList li": {
            color: "#dcf3ff",
          },
          ".MuiMenu-list li": {
            color: "#dcf3ff",
          },
          ".MuiPaginationItem-root": {
            color: "#9bbcd1",
            backgroundColor: "#1e2a31",
          },
        }}
      />
      <Stack alignItems="center" direction="row" sx={{ pl: 2 }}>
        <Link to="/dashboard">
          <HomeIcon style={{ color: "white" }} />
        </Link>
        <Link to="/dashboard">
          {" "}
          <h3 className="navbar_main_title" />
        </Link>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="baseline">
        <p onClick={handleClick} className="user_name_navbar">
          {customer_name}
        </p>
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <AccountCircleIcon sx={{ color: "white", fontSize: "30px" }} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Link to="/userprof" style={{ width: "0px" }}>
              <MenuItem onClick={handleClose}>Profile</MenuItem>
            </Link>
            <MenuItem
              onClick={() => {
                if (account_type !== "PAID_CUSTOMER") return;
                dispatch(handle_open_channel_scrape_modal(true));
                handleClose();
              }}
            >
              Add to scrapping{" "}
              <span className="user_add_to_scrape_access_info">premium</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                sessionStorage.clear();
                handle_clear_localStorage();
                window.location.href = "/";
                sessionStorage.clear();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Stack>
    </div>
  );
}

export default Usernavstats;
