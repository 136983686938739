import React from "react";
import PropTypes from "prop-types";
import "../../../../css/Sections/Tooltip/tooltip.css";

function CustomizedTooltip({ children, tooltip_text }) {
  return (
    <div data-info={tooltip_text} className="customized_tooltip_box">
      {children}
    </div>
  );
}
CustomizedTooltip.propTypes = {
  children: PropTypes.any,
  tooltip_text: PropTypes.string,
};

export default CustomizedTooltip;
